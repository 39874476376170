<template>
  <TopBar />
  <NavBar />
  <DefaultBanner title="About Us" :class="['about-us-banner-bg']" />
  <div class="w-100 lg:pt-20 relative overflow-hidden">
    <div
      class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-between
        items-center
      "
    >
      <img
        class="lg:w-auto w-full absolute left-0 top-0 hidden lg:block"
        src="/images/map-left.png"
      />
      <img
        class="lg:w-auto w-1/2 absolute left-0 top-0 lg:hidden"
        src="/images/map-right.png"
      />
    </div>
    <div
      class="
        container
        mx-auto
        lg:px-0
        px-5
        py-6
        z-10
        relative
        flex flex-row
        items-stretch
        lg:mb-20
        mb-12
      "
    >
      <div class="flex flex-col lg:w-1/2 w-full">
        <h5 class="font-medium text-base text-black-light uppercase mb-2">
          Why FairPy?
        </h5>
        <h2
          class="
            text-blue-darker text-4xl
            font-extrabold font-display
            lg:leading-snug
            leading-tight
            mb-5
          "
        >
          In pursuit of <span class="text-blue">fariness</span> in every
          transaction
        </h2>
        <div class="h-1 w-28 border-b-2 border-blue mb-12"></div>
        <div class="mb-8 lg:hidden">
          <img class="w-full h-auto" src="/images/who-are-we.png" />
        </div>
        <p class="text-lg font-bold mb-6">
          We have a passion for saving for our customers – their savings is our
          success.
        </p>
        <p class="text-base text-black-lighter font-thin mb-6">
          Consumers are often faced with conflicting data that prevent them from
          making smart purchases. The Auto industry offers expensive options
          that not only decrease consumers’ net value but also increase their
          debt burden.
        </p>
        <p class="text-base text-black-lighter font-thin">
          Instead, we believe in maximizing the value of our customers by
          providing them the best deals available in the market. We provide a
          seamless platform that eliminates the middleman and enables customers
          to make best data-driven decisions in purchasing pre-owned vehicles.
          We have a passion for saving for our customers – their savings is our
          success.
        </p>
      </div>
      <div class="lg:flex lg:w-1/2 hidden pl-14">
        <div>
          <img class="h-full w-auto max-w-none" src="/images/who-are-we.png" />
        </div>
      </div>
    </div>
  </div>
  <div
    class="
      w-100
      relative
      overflow-hidden
      lg:vision-mission-bg
      vision-mission-mobile-bg
      lg:p-16
      px-5
      py-12
    "
  >
    <h1 class="text-center font-extrabold text-4xl text-white">
      Our
      <span class="text-blue-light block lg:inline">Vision & Mission</span>
    </h1>
    <div
      class="
        mt-10
        lg:p-8
        px-4
        py-8
        border-2 border-blue-light
        text-center
        container
        mx-auto
        lg:mb-8
      "
    >
      <p class="text-4.5xl font-special text-white leading-tight">
        To be Earth’s fairest company
      </p>
      <p class="text-white mt-3 mb-3">
        To offer creative solutions that are centered around transparency and
        fairness leading to genuine financial freedom
      </p>
    </div>
  </div>
  <div class="w-100 relative overflow-hidden lg:p-20 p-5 pt-20 pb-0 bg-white">
    <div
      class="
        absolute
        z-0
        left-0
        right-0
        top-0
        bottom-0
        flex flex-row
        justify-between
        items-center
      "
    >
      <img
        class="lg:w-auto w-1/2 absolute right-0 top-0"
        src="/images/map-right.png"
      />
      <img
        class="lg:w-auto w-1/2 absolute left-0 bottom-36"
        src="/images/dots.png"
      />
    </div>
    <h1
      class="
        lg:text-center
        text-left
        font-extrabold
        text-4xl
        mb-4
        text-blue-darker
        font-display
      "
    >
      Our <span class="text-blue">Core</span> Values
    </h1>
    <p class="lg:text-center text-left uppercase font-medium text-blue-darker">
      WE ARE GUIDED BY OUR FOLLOWING PRINCIPLES
    </p>
    <div
      class="
        container
        mx-auto
        flex flex-row
        items-stretch
        relative
        lg:py-16 lg:mt-0
        mt-4
        justify-center
      "
    >
      <div
        class="
          flex flex-col
          items-center
          lg:pt-16 lg:pb-16
          py-8
          lg:pr-0
          pr-2
          flex-grow
          text-center
          basis-0
        "
      >
        <div
          v-for="(item, index) in coreValues['set1']"
          :key="index"
          :data="index"
          class="
            flex flex-col
            items-center
            lg:justify-center
            justify-start
            text-center
            flex-grow
            h-44
            lg:h-auto
            mb-8
            lg:text-base
            text-sm
          "
        >
          <img :src="item.icon" />
          <p class="font-bold text-xl text-black-lighter mt-4">
            {{ item.title }}
          </p>
        </div>
      </div>
      <div class="hidden lg:inline-block">
        <img class="mx-auto" src="/images/core-values-car.png" />
      </div>
      <div
        class="
          flex-grow flex flex-col
          items-center
          lg:pt-16 lg:pb-16
          py-8
          lg:pl-0
          pl-2
          text-center
          basis-0
        "
      >
        <div
          v-for="(item, index) in coreValues['set2']"
          :key="index"
          :data="index"
          class="
            flex flex-col
            items-center
            lg:justify-center
            justify-start
            flex-grow
            h-44
            mb-8
            lg:h-auto lg:text-base
            text-sm
          "
        >
          <img :src="item.icon" />
          <p class="font-bold text-xl text-black-lighter mt-4">
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import TopBar from "../components/TopBar.vue";
import NavBar from "../components/NavBar.vue";
import DefaultBanner from "../components/DefaultBanner.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    TopBar,
    NavBar,
    DefaultBanner,
    Footer,
  },
  data() {
    return {
      coreValues: {
        set1: [
          {
            icon: "/images/core-values/icon1.png",
            title: "Fairness for all",
          },
          {
            icon: "/images/core-values/icon2.png",
            title: "Obssesed about Customer Success",
          },
          {
            icon: "/images/core-values/icon3.png",
            title: "Striving for Win-Win",
          },
        ],
        set2: [
          {
            icon: "/images/core-values/icon4.png",
            title: "Finding Creative Solutions",
          },
          {
            icon: "/images/core-values/icon5.png",
            title: "Sustainable",
          },
          {
            icon: "/images/core-values/icon6.png",
            title: "Perfect Assesment",
          },
        ],
      },
    };
  },
};
</script>

<style></style>